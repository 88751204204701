import gql from 'graphql-tag';

export default ({ ADDITIONAL_INVOICE_INFORMATION = false,
  PBI_INVOICE = false,
  PBI_REGISTRATION = false,
  BTMS_DRIVER_REGISTRATION = false,
  FREE_TRIAL = false,
  QUOTA_LIMIT_AND_DEACTIVATION = false,
  KC_SCHEDULE_DISBURSEMENT = false } = {}) => gql`
query PROFILE($companyKsuid: String!) {
  myEmployeeProfile(companyKsuid: $companyKsuid) {
    accessType
    accessTypes
    company {
      signatureName
      signatureTitle
      address
      email
      handle
      hasConsentedCustomer
      isDofEligible
      isPodfEligible
      isWalletEnabled
      documents {
        documentType {
          name
        }
        metadata
      }
      ${PBI_INVOICE ? `
      isPkp
      ` : ``}
      ksuid
      isPartneredWithEnterpriseShipper
      logo {
        fileName
        uploadedAt {
          date
        }
        url {
          full
        }
      }
      verifications {
        currentActiveCompanyStatus
      }
      ${BTMS_DRIVER_REGISTRATION ? `
      driverPbiRegistrationSummary {
        totalActiveDriver
        totalPendingDriver
        totalRejectedDriver
      }
      ` : ``}
      
      ${PBI_REGISTRATION ? `
      ownerType
      ownerName
      pbiTransporterVerification {
        currentActiveCompanyStatus
        requestedCompanyStatus
        verificationStatus
        reason
        message
        suspensionStartDatetime
        suspensionEndDatetime
      }
      ` : ``}
      ${FREE_TRIAL ? `
      nftFreeTrialInformation {
        startDatetime
        endDatetime
      }
      isNftFreeTrialExpired
      ` : ``}
      nftSubscriber
      ${QUOTA_LIMIT_AND_DEACTIVATION ? `
      nftDeactivationType
      ` : ``}
      metadata
      name
      phoneNumber
      status
      type
      vehicleUtilizationTarget {
        unit
        value
      }
      ${KC_SCHEDULE_DISBURSEMENT ? `
        paymentDetail {
          paymentSchedule
          paymentScheduleUpdatedAt
          minInvoiceAmount
          minInvoiceAmountUpdatedAt
        }
      ` : ``}
    }
    email
    ksuid
    name
    user {
      phoneNumber
      email
      defaultLocale
    }
  }
  companySettings(companyKsuid: $companyKsuid) {
    # name inside company details here is only used in tms admin
    # for opening a transporter detail page
    companyDetails {
      name
    }
    companyDocuments {
      ktpFile {
        fileName
        uploadedAt {
          datetime
        }
        url {
          full
        }
      }
      npwpFile {
        fileName
        uploadedAt {
          datetime
        }
        url {
          full
        }
      }
      sppkpFile {
        fileName
        uploadedAt {
          datetime
        }
        url {
          full
        }
      }
    }
  }
  companyBankAccounts(companyKsuid: $companyKsuid) {
    ksuid
    accountHolder
    accountNumber
    isDefault
    bank {
      ksuid
      name
      ${ADDITIONAL_INVOICE_INFORMATION ? `
      bankCode
      swiftCode
      ` : ``}
    }
  }
}
`;
