import { get } from 'lodash-es';
import { Message } from '@kargotech/tms-ui/components';
import { generateClient as commonGenerateClient } from '@kargotech/tms-common/Services/apollo';
import { COMPANY_KSUID_STORAGE_KEY } from '@kargotech/tms-core/auth/constants';
import {
  APP_CONTEXT_STORAGE_KEY,
  KODE_LAMBUNG_ERROR_CODE,
  SERVICES_URL,
} from '~/Configurations/constants';

import i18next from './i18n';
import Auth from '../Configurations/auth';
import { UNCAUGHT_NETWORK_ERROR } from '~/Pages/Shipper/ShipperForm/constant';
// import fragmentTypes from '~/Services/apollo/fragmentTypes';

export const generateClient = (uri = '', skipAuth = false) => {
  async function setBearerToken(headers) {
    // get the authentication token from local storage if it exists
    const cognitoCurrentSession = await Auth.currentSession();
    const token = cognitoCurrentSession.getIdToken().getJwtToken();

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  }

  const appRoleSetter = () => (localStorage.getItem(APP_CONTEXT_STORAGE_KEY) || 'TRANSPORTER').toLowerCase();
  const companyKsuidSetter = () => localStorage.getItem(COMPANY_KSUID_STORAGE_KEY);

  return commonGenerateClient({
    uri,
    skipAuth,
    bearerTokenSetter: setBearerToken,
    appRoleSetter,
    companyKsuidSetter,
    onBackendError: (error, operation) => {
      if (get(operation, 'variables.SKIP_TOAST')) {
        return;
      }
      const GENERAL_ERROR_CODE = [500, 401, '018-5500'];
      if (
        (error.status_code && GENERAL_ERROR_CODE.includes(error.status_code))
        || (error.error_code && GENERAL_ERROR_CODE.includes(error.error_code))
      ) {
        Message.error(i18next.t(`message:errors.backend.common.${error.status_code || error.error_code}`));
      } else if ((error.status_code || error.error_code) && (error.path || []).length > 0) {
        const errorCode = error.status_code || error.error_code;
        if (errorCode !== KODE_LAMBUNG_ERROR_CODE) {
          Message.error(i18next.t(`message:errors.backend.${error.path[0]}.${errorCode}`, error.message));
        }
      } else {
        Message.error(i18next.t('message:errors.unexpected'));
      }
    },
    onNetworkError: networkError => {
      if (networkError === UNCAUGHT_NETWORK_ERROR.NO_CURRENT_USER) {
        return;
      }
      if (networkError.statusCode === 500) {
        Message.error(i18next.t('message:errors.network:500'));
      } else {
        Message.error(i18next.t('message:errors.network.common'));
      }
    },
    onUnexpectedError: () => Message.error(i18next.t('message:errors.unexpected')),
  });
};

export const APOLLO_CLIENTS = Object.keys(SERVICES_URL).reduce((accumulator, serviceKey) => ({
  ...accumulator,
  [serviceKey]: generateClient(
    SERVICES_URL[serviceKey],
    false,
  ),
}), {});

export default {};
