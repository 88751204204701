import React, { createContext, useContext, useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash-es';

import { ProfileContext } from '~/Contexts/ProfileProvider';
import COMPANY_NFT_MODULES from '~/GraphQL/ProfileService/Queries/companyNftModules';
import { APOLLO_CLIENTS } from '~/Services/apollo';

export const NftModulesContext = createContext();

const NftModulesProvider = ({ children }) => {
  const { getSelectedCompany } = useContext(ProfileContext);
  const {
    data: nftModulesData,
  } = useQuery(COMPANY_NFT_MODULES, {
    client: APOLLO_CLIENTS.PROFILE,
    fetchPolicy: 'network-only',
    variables: {
      companyKsuid: getSelectedCompany().ksuid,
    },
  });
  const extractedModules = useMemo(
    () => Object.fromEntries(get(
      nftModulesData,
      'companyNftModules',
      [],
    ).map(nftModule => [nftModule.id, nftModule.isActive])),
    [nftModulesData],
  );

  const modulesArray = useMemo(
    () => get(
      nftModulesData,
      'companyNftModules',
      [],
    ).filter(nftModule => nftModule.isActive)
      .map(nftModule => nftModule.id),
    [nftModulesData],
  );

  return (
    <NftModulesContext.Provider
      value={{
        modulesArray,
        ...extractedModules,
      }}
    >
      {children}
    </NftModulesContext.Provider>
  );
};

export default NftModulesProvider;
