/**
 * Check if the current NFT platform is for The Lorry's transporter or not
 * TODO:  implement logic after the Lorry infra separation is finalized
 *        currently differentiate using query string <NFT url>?lorry=true
 * @returns {Boolean}
 */
export default function checkTheLorryTransporter() {
  // Use local storage to test in local environment
  if (process.env.NODE_ENV === 'development') {
    return window.localStorage.getItem('lorry') === 'true';
  }

  return window.location.origin.includes('thelorry.com');
}
