import React, { useState, createContext, useCallback, useMemo, useContext, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { get, intersection } from 'lodash-es';
import { useFlag } from '@unleash/proxy-client-react';

import { AuthContext } from '@kargotech/tms-core/auth';
import {
  COMPANY_STATUSES,
  COMPANY_VERIFICATION_LEVEL,
  EXPIRED_SUBSCRIPTION_LOGIN_FLAG,
  FREE_TRIAL_LOGIN_FLAG,
  NFT_DEACTIVATION_TYPE,
  PBI_PARTNERSHIP_STATUSES,
  USER_ACCESS_TYPE,
} from '~/Configurations/constants';
import { getUserProfile } from '~/Models/userProfile';
import PROFILE from '~/GraphQL/ProfileService/Queries/profile';
import { APOLLO_CLIENTS } from '../Services/apollo';
import sentry from '~/Services/sentry';
import { formatCountDaysFromEndToStartDate } from '~/Utilities/formatter';

export const ProfileContext = createContext();

function ProfileProvider({ children }) {
  const ADDITIONAL_INVOICE_INFORMATION = useFlag('TC-5401_ADDITIONAL_INVOICE_INFORMATION');
  const PBI_REGISTRATION = useFlag('TC-5507_PBI_REGISTRATION');
  const PBI_INVOICE = useFlag('TC-6669_PBI-INVOICE');
  const BTMS_DRIVER_REGISTRATION = useFlag('TC-5658_BTMS-DRIVER-REGISTRATION');
  const FREE_TRIAL = useFlag('TC-6138_FREE-TRIAL');
  const QUOTA_LIMIT_AND_DEACTIVATION = useFlag('TC-6721_QUOTA-LIMIT-AND-DEACTIVATION');
  const KC_SCHEDULE_DISBURSEMENT = useFlag('KC_SCHEDULE_DISBURSEMENT');

  const { selectedCompanyKsuid, logout } = useContext(AuthContext);
  const [profile, setProfile] = useState(getUserProfile(null));

  const pbiTransporterVerification = get(profile, 'company.pbiTransporterVerification');
  const { requestedCompanyStatus,
    verificationStatus,
    suspensionStartDatetime,
    suspensionEndDatetime } = pbiTransporterVerification || {};

  const resetProfile = useCallback(() => setProfile(getUserProfile(null)), []);

  const searchParams = new URLSearchParams(window.location.search);
  const isLiteMode = searchParams?.get('litemode') || localStorage.getItem('litemode');
  localStorage.setItem('litemode', isLiteMode);
  const getSelectedCompany = useCallback(() => {
    const { ksuid, ...restProfileAttrrs } = profile?.company || {};
    return {
      ksuid: selectedCompanyKsuid || ksuid,
      ...restProfileAttrrs,
    };
  }, [profile, selectedCompanyKsuid]);

  const { refetch: getProfile } = useQuery(PROFILE(
    { ADDITIONAL_INVOICE_INFORMATION,
      PBI_INVOICE,
      PBI_REGISTRATION,
      BTMS_DRIVER_REGISTRATION,
      FREE_TRIAL,
      QUOTA_LIMIT_AND_DEACTIVATION,
      KC_SCHEDULE_DISBURSEMENT },
  ), {
    client: APOLLO_CLIENTS.PROFILE,
    fetchPolicy: 'network-only',
    skip: !selectedCompanyKsuid,
    notifyOnNetworkStatusChange: true,
    variables: {
      companyKsuid: selectedCompanyKsuid,
    },
    onCompleted: profileData => {
      const formattedProfileData = getUserProfile(profileData);
      setProfile(formattedProfileData);

      // set sentry user context
      sentry.setUser({
        id: formattedProfileData.ksuid,
        companyId: formattedProfileData.company.ksuid,
        email: formattedProfileData.email,
        phone: formattedProfileData.phoneNumber,
      });
    },
    onError: resetProfile,
  });

  const getCompanyMetadata = useCallback(() => {
    const { metadata } = getSelectedCompany();
    if (!metadata || typeof metadata !== 'string') {
      return undefined;
    }

    try {
      return JSON.parse(metadata);
    } catch {
      return undefined;
    }
  }, [getSelectedCompany]);

  const isNftFreeTrialExpired = useMemo(() => get(getSelectedCompany(), 'isNftFreeTrialExpired')
    && get(getSelectedCompany(), 'status') === COMPANY_STATUSES.INACTIVE, [getSelectedCompany]);

  useEffect(() => {
    if (isNftFreeTrialExpired) {
      localStorage.setItem(FREE_TRIAL_LOGIN_FLAG, true);
      logout();
    }
  }, [isNftFreeTrialExpired, logout]);

  const isNftSubscriptionExpired = useMemo(() => (get(getSelectedCompany(), 'nftDeactivationType')
   === NFT_DEACTIVATION_TYPE.NFT_SUBSCRIPTION_EXPIRED)
  && (get(getSelectedCompany(), 'status') === COMPANY_STATUSES.INACTIVE),
  [getSelectedCompany]);

  useEffect(() => {
    if (isNftSubscriptionExpired) {
      localStorage.setItem(EXPIRED_SUBSCRIPTION_LOGIN_FLAG, true);
      logout();
    }
  }, [isNftSubscriptionExpired, logout]);

  useEffect(() => {
    if (['SME_TELESALES', 'SME_SALES_EXECUTIVE'].includes(profile?.company?.accessType)) {
      logout();
    }
  }, [profile, logout]);

  const getFirstMileCompany = useCallback(
    () => {
      try {
        /* eslint-disable camelcase */
        const {
          is_first_mile,
          is_first_mile_transporter,
          ...otherProps
        } = getCompanyMetadata() || {};

        if (is_first_mile) {
          return {
            isFirstMileShipper: true,
            ...otherProps,
          };
        }

        if (is_first_mile_transporter) {
          return {
            isFirstMileTransporter: true,
            ...otherProps,
          };
        }
        /* eslint-enable camelcase */

        return null;
      } catch (_) {
        return null;
      }
    },
    [getCompanyMetadata],
  );

  const isFirstMileShipper = useMemo(() => {
    const firstMileCompany = getFirstMileCompany();
    return Boolean(firstMileCompany && firstMileCompany.isFirstMileShipper);
  }, [getFirstMileCompany]);

  const isFirstMileTransporter = useMemo(() => {
    const firstMileCompany = getFirstMileCompany();
    return firstMileCompany && firstMileCompany.isFirstMileTransporter;
  }, [getFirstMileCompany]);

  const getPbiVerificationStatus = useMemo(() => {
    const isVerificationStatusActive = verificationStatus === PBI_PARTNERSHIP_STATUSES.ACTIVE;
    let companyVerificationStatus = '';
    if (requestedCompanyStatus === COMPANY_VERIFICATION_LEVEL.CREATED
      && ![PBI_PARTNERSHIP_STATUSES.BLOCKED, PBI_PARTNERSHIP_STATUSES.SUSPENDED].includes(verificationStatus)) {
      companyVerificationStatus = 'NOT_REGISTERED';
    } else if (isVerificationStatusActive && requestedCompanyStatus === COMPANY_VERIFICATION_LEVEL.VERIFIED) {
      companyVerificationStatus = 'VERIFIED';
    } else if (isVerificationStatusActive && requestedCompanyStatus === COMPANY_VERIFICATION_LEVEL.TRUSTED) {
      companyVerificationStatus = 'TRUSTED';
    }
    return companyVerificationStatus || verificationStatus || PBI_PARTNERSHIP_STATUSES.NOT_REGISTERED;
  }, [requestedCompanyStatus, verificationStatus]);

  const getPbiSuspensionDuration = useMemo(() => formatCountDaysFromEndToStartDate(
    suspensionStartDatetime,
    suspensionEndDatetime,
  ), [suspensionStartDatetime, suspensionEndDatetime]);

  /**
   * Check logged-in user roles are matching with allowed roles.
   * Logged-in user roles is stored in localStorage under selectedCompany.accessTypes property.
   * Allowed roles should be any value from ACCESS_PRIVILEGE.
   * The logged-in user roles will be intersecting with the provided Allowed roles excluding RESTRICTED_ACCESS
   * User is authorized if there's one or more matching roles
   * @require src/Configurations/accessPrevillegeMap.js#ACCESS_PRIVILEGE
   * @example
   * isAuthorizedToAccess(ACCESS_PRIVILEGE.SHIPMENT_READ);
   * @param {String[]} roles must-have roles
   * @returns {Boolean} is user authorized or not
   */
  const isAuthorizedToAccess = useCallback(
    (roles = []) => {
      const allowedRoles = roles.filter(role => role !== USER_ACCESS_TYPE.RESTRICTED_ACCESS);
      const userAccessTypes = getSelectedCompany()
        .accessTypes?.filter(accessType => accessType !== USER_ACCESS_TYPE.RESTRICTED_ACCESS) || [];

      return !!intersection(allowedRoles, userAccessTypes).length;
    },
    [getSelectedCompany],
  );

  return (
    <ProfileContext.Provider
      value={{
        profile,
        getProfile,
        resetProfile,
        getSelectedCompany,
        isAuthorizedToAccess,
        getFirstMileCompany,
        isFirstMileShipper,
        isFirstMileTransporter,
        getCompanyMetadata,
        getPbiVerificationStatus,
        getPbiSuspensionDuration,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
}

export default ProfileProvider;
